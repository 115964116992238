@import '@angular/material/theming';
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

// Always include only once per project
// @include mat-core();

// Import our custom theme
// @import 'sass/app-theme.scss';
@import 'sass/fonts.scss';
@import 'sass/cartus-palette.scss';
@import 'sass/app.scss';
@import '@angular/cdk/overlay-prebuilt.css';
// @mixin custom-components-theme($theme, $typography, $cartus-palette) {
//     @include app($theme, $typography, $cartus-palette);
// }

// @include angular-material-theme($app-theme);

// .ccu-theme {
//     // use our theme with angular-material-theme mixin
//     @include angular-material-theme($app-theme);
//     // include custom components theme mixin once per theme class
//     @include custom-components-theme($app-theme, $typography, $cartus-palette);
// }
  
html, body {
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    height: 100vh;
    min-width: 331px;
    overflow: visible !important;
    font-family: "Avenir-Book", sans-serif;
    -moz-osx-font-smoothing: grayscale; /* Fix blurry font for Firefox Mac users connected to external displays */

}

*[fxlayoutgap] > *:last-child {
    margin-right: 0; /* Fix for flex last column in a row having a right margin */
}

.mat-select-panel {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0em 0.25em 0.5em #0000000A;
    border: 0.0625em solid #E6E6E6;
    border-radius: 0.5em;
    opacity: 1;
    margin-top: 2.2em;
    margin-left: 0.25em;
}

.mat-form-field {
    height: 3.14em;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.0625em solid #E6E6E6;
    border-radius: 0.5em;
}  

.mat-focused {
    // border-color: #2065F8;
}

.tooltip-list {
    white-space: pre;
    font-family: "Avenir-Medium", sans-serif !important;
    font-size: 0.875em !important;
    line-height: 1.875em !important;
    letter-spacing: 0.29px  !important;
    color: #FFFFFF !important;
    opacity: 1;
    padding-right: 3.75em !important;
    max-width: 172px !important;
}
