@import '../sass/cartus-palette.scss';
@import '../sass/button-styles.scss';
@import '../sass/dialog.scss';

html {
  height: 100%;
}

body {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #F7F7F7;
  // background: map-get($cartus-palette, 'cartus_light_gray');
}


mat-card {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 8px #0000000A;
  border: 1px solid #E6E6E6;
  border-radius: 8px

}

mat-icon {
  cursor: pointer;
}

.mat-dialog-title{
  position: relative;
}
.mat-dialog-close-icon{
  position: absolute;
  top: -0.6em;
  right: -0.6em;
  min-width: auto;
}
.mat-form-field {
  width: 100%;
  height: 3.2em;
}

.mat-form-field.mat-text-area {
  min-height: 8.75em;
}



.mat-form-field-appearance-outline 
 {
  .mat-form-field-outline-thick,
  .mat-form-field-outline-end,
  .mat-form-field-outline-gap,
  .mat-form-field-outline-start {
    border: map-get($cartus-palette , 'cartus-light-gray') !important;
    border: 0 !important;
    transition: none !important;
  }
  .mat-form-field-prefix,
  .mat-form-field-suffix {
    top: 0.5em !important;
  }
 .mat-form-field-infix {
    padding: 1.1em 0 1.1em;
  }

}

.mat-select-arrow {
  color:transparent;
}
.mat-form-field {
  &.mat-focused {
    &.mat-primary .mat-select-arrow {
      color:transparent;
    }
  }
}

.mat-form-field-infix {
  border-top: 0em solid transparent;
}

.mat-form-field-label {
  background-color :#FFFFFF;
  color: #707070;
  width: auto !important;
}
.mat-form-field.mat-focused .mat-form-field-label {
  color: #2065F8;
}
.mat-form-field-appearance-outline {
  border:1px solid #E6E6E6;
  border-radius: 8px;

}

.mat-form-field.mat-form-field.mat-focused {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #2065F8;
}

.mat-form-field-prefix .mat-icon, .mat-form-field-suffix .mat-icon{
  // width: 0.8em;
    font-size: 26px;
    // width: 16px;
    // height: 16px;
}
.mat-dialog-container{
  background: #F7F7F7;
  // max-width: 32.74em;
  border-radius: 0.5em !important;
  overflow: auto;
}
.mat-dialog-content {
  margin: 1.627em 0em !important;
  border-top: 0.0625em solid #CCCCCC;
  padding: 1.125em !important;
  padding-bottom: 2.5em !important;
  padding-right: 0.5em!important;
  background-color: #FFFFFF;
  font-size: 1em;
  line-height: 1.5em;
  letter-spacing: 0em;
  color: #222222;
  opacity: 1;
  font-family: "Avenir-Medium", sans-serif;
}
.mat-dialog-title img {
  padding: 1.125em !important;
}
.mat-dialog-title .restore-icon {
  padding: 0.8em !important;
  color: #438325;
  font-size: 1.8em;
}
.footer-row {
  line-height:1.25em;
  font-size: 0.75em;
  letter-spacing: 0em;
  color: #575A5D;
  opacity: 1;
  padding: 1.875em 0em;

  .privacy-st-span {
      // border-right:0.0625em solid #CCCCCC;
      // border-left:0.0625em solid #CCCCCC;
  }
}

.toast-container .ngx-toastr{
  opacity: 0 !important;
}
.toast-container .ngx-toastr:hover{
  opacity: 0 i !important;
}
.toaster_row{
  width: 23.75em;
  background: #FFFFFF;
  border-radius:0.5em ;

  p {
    margin: 0;
    padding: 0.6em;
  }

  .title{
    line-height: 1.25em;
    margin: 0;
    letter-spacing: -0.011875em;
    color: #222222;
    opacity: 1;
    font-size: 1em;
    font-weight: 600;
  }

  .title_icon{
    line-height: 1.25em;
    margin: 0;
    padding-top: 0.6em;
    letter-spacing: -0.011875em;
    color: #222222;
    opacity: 1;
    font-size: 1em;
    font-weight: 600;
  }

  .message{
    line-height: 1.25em;
    margin: 0;
    letter-spacing: -0.011875em;
    color: #707070;
    opacity: 1;
    font-size: 1em;
  }

  box-shadow: 2px 2px 8px #cccccc;
}
.icon_backgroung{
  background-color: #438325;
  border-top-left-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
}
.icon_error_backgroung{
  background-color: #d72a29;
}
.icon_warning_background {
  background-color: #ffc203;
}

.toast-top-right {
  right: 2.5em  !important;
  top:5.25em  !important;
}
.mat-tooltip {
  font-size: 0.75em;
  line-height: 1em;
  letter-spacing: 0.0182em;
  color: #FFFFFF;
  opacity: 1;
  background-color: #222222;
}
.mat-select {
  font-family: "Avenir-Medium", sans-serif;
  color:#222222;
  letter-spacing: 0em;
  font-weight: normal;
}
.mat-option-text{
  color: #222222;
  font-family: "Avenir-Medium", sans-serif;
  font-weight: normal;
}

.mat-form-field-should-float .mat-form-field-label {
  margin-top: -0.2em;
}

.mat-form-field-appearance-outline .mat-form-field-label {
  top: 2em;
  margin-top: -0.25em;
}

.mat-form-field-textarea {
  height: auto;
  textarea {
    resize: none;
  }
 
}
.mat-form-field-label-wrapper {
  top:-0.725em
}

.multi_display_drodown{
  height: auto;
  .mat-form-field-label {
    transform: translateY(-1.57498em) scale(0.75);
  }
  mat-label {
    color: #002F82;
    font-size: 0.75em;
    line-height: 1em;
    letter-spacing: 0.1em;
    opacity: 1;
    font-family: "Avenir-Medium", sans-serif;
  }
}

.mat_chip_div {
  background-color: #e6e6e6;
  padding: 0.5em 0.75em;
  border-radius: 1em;
  opacity: 1;
  margin-bottom: 0.0675em;
  span {
    font-family: "Roboto", sans-serif;
    font-size: 0.875em;
    line-height: 1.25em;
    letter-spacing: 0.015625em;
    color: #000000DE;
    opacity: 1;
    
  }

  .mat-icon {
    margin-left: 0.4em;
    color: #00000099;
    font-size: 14px;
    width: 14px;
    height: 14px;
  }
}

.mat-autocomplete-panel {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 8px #0000000a;
  border-radius: 8px;
  opacity: 1;

  .mat-option {
    height: auto !important;
    white-space: normal; 
    padding: 0.3em;

    .prod-description {
      font-size: 0.8em;
    }
  }
   
  .mat-option-text {
    letter-spacing: 0.15px;
    color: #222222;
    opacity: 1;
    line-height: 20px;
    font-size: 16px;
    font-family: "Avenir-Medium", sans-serif;
    
  }
}

.mat-autocomplete-panel::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  border-radius: 0.5em;
  background-color: transparent;
}

.mat-autocomplete-panel::-webkit-scrollbar {
  width: 0.3em;
  background-color:transparent;
}

.mat-autocomplete-panel::-webkit-scrollbar-thumb {
  border-radius: 0.5em;
  -webkit-box-shadow: none;
  background-color: #CCCCCC;
}


.mat-form-field-should-float {
  mat-label{
      font-size: 0.75em;
      line-height: 1em;
      letter-spacing: 0.1em;
      color: #002F82;
      opacity: 1;
      font-family: "Avenir-Medium", sans-serif;
  }
}



.restrictions_overlay {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 8px #0000000A;
  border: 1px solid #E6E6E6;
  border-radius: 8px;
  opacity: 1;
  padding: 12px 14px;
  overflow-y: scroll;
}
.restrictions_overlay::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  border-radius: 0.5em;
  background-color :#FFFFFF;
}

.restrictions_overlay::-webkit-scrollbar {
  width: 0.3em;
  background-color:transparent;
}

.restrictions_overlay::-webkit-scrollbar-thumb {
  border-radius: 0.5em;
  -webkit-box-shadow: none;
  background-color: #CCCCCC;
}
.restrictions_overlay_type {
  font-weight: 900;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: #222222;
  opacity: 1;
  padding-left: 16px;
}
.restrictions_overlay_ul {
  list-style-type: none;
  padding-left: 0;
  margin-top: 12px;
  margin-bottom: 0;

  li {
    padding: 12px 16px;
    min-width: 338px;
    cursor: pointer;
    margin-bottom: 6px;

    mat-icon {
      width: 1em;
      height: 1em;
      font-size: 1.25em;
      color: #707070;
      float: right;
    }
  }

  li.active {
    background: #F7F7F7 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;

    span {
      font: normal normal medium 16px/20px Avenir, sans-serif;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.19px;
      color: #222222;
      opacity: 1;
    }
    mat-icon {
      width: 20px;
      height: 20px;
      font-size: 20px;
      visibility: visible;
      color: #2065F8;
    }
  }
}

.restrictions_overlay_span{
  font: normal normal medium 16px/20px Avenir, sans-serif;
  font-size: 12px;
  line-height: 20px;
  font-family: "Avenir-Medium", sans-serif;
  letter-spacing: -0.19px;
  color: #AAAAAA;
  opacity: 1;
}

// add to template styles
.add-template-modalbox {
  .mat-dialog-container {
    margin-top: 2.625em;
  }
  .mat-dialog-container::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border-radius: 0.5em;
    background-color: transparent;
  }
  
  .mat-dialog-container::-webkit-scrollbar {
    width: 0.3em;
    background-color:transparent;
  }
  
  .mat-dialog-container::-webkit-scrollbar-thumb {
    border-radius: 0.5em;
    -webkit-box-shadow: none;
    background-color: #CCCCCC;
  }
  #mat-dialog-title-0 {
    margin: 0;
    font-size: 1.13em;
    line-height: 1.13em;
    letter-spacing: 0px;
    color: #222222;
    opacity: 1;
  }
  .tab_row {
    margin: 1em 0em;
  }
  .tab-div {
    width: 12.5em;
    height: 3.375;
    padding:0em 1.25em;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0em 0.25em 0.5em #0000000a;
    border: 0.0625em solid #E6E6E6;
    border-radius: 0.5em;
    opacity: 1;
    cursor: pointer;

    p{
      font-family: "Avenir-Medium", sans-serif;
      font-size: 1em;
      line-height: 1.25em;
      letter-spacing: -0.19px;
      color: #707070;
      opacity: 1;
    }
  }

  .tab-div-active {
    background: #2065F8 0% 0% no-repeat padding-box;
    box-shadow: 0em 0.25em 0.5em #0000000A;
    color: #FFFFFF;

    p {
        color: #FFFFFF;
    }

  }

  .search_input {
    width: 90%;
    border: none;
    height: 1.875em;
    outline: none;
    font-family: "Avenir-Medium", sans-serif;
  }
  .serch_input_row{
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border: 0.0625em solid #E6E6E6;
      border-radius: 0.5em;
      opacity: 1;
      height: 2.87em;

  }

  .search_icon {
    color: #707070;
  }
  .template_list_section {
    height:41em;
    overflow-y: scroll;
  }
  .template_list_section::-webkit-scrollbar {
    display: none;
  }
  .incremental_checkbox {
    width: 1.125em;
    height: 1.125em;
    margin-right: 1.4375em;
  }
  .incremental_lbl {
    font-family: "Avenir-Medium", sans-serif;
    font-size: 1em;
    line-height: 1.375em;
    letter-spacing: 0.02375em;
    color: #222222;
    opacity: 1;
  }
  .template_section {
    padding: 12px 10px 10px 13px;
    border: 2px solid #E6E6E6;
    border-radius: 8px;
    opacity: 1;
    margin: 6px 0px;
    // background: #FFFFFF 0% 0% no-repeat padding-box;
    // border: 0.0625em solid #2065F8;
    // border-radius: 0.5em;
    // opacity: 1;
  }
  .selected_templates {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.0625em solid #2065F8;
    border-radius: 0.5em;
    opacity: 1;
  }
  .template_section_div {
    width: 100%;
  }
  .template_section_row:first-child {
    margin-top: 12px;
  }
  .footer_content{
    position: fixed;
    bottom: 0.0625em;
    padding: 1.25em 1em;
    width: 800px;
    margin-left: -24px;
    background: #F7F7F7 0% 0% no-repeat padding-box;
    box-shadow: 0px -4px 8px #0000000a;
    border-radius: 0px 0px 8px 8px;
    opacity: 1;
  }
  .mat-dialog-close {
    font-size: 1em;
    line-height: 1.25em;
    letter-spacing: -0.011875em;
    color: #2065F8;
    opacity: 1;
    box-shadow: 0em 0.25em 0.5em #0000000A;
    border: 2px solid #2065F8;
    border-radius: 0.5em;
    padding: 0.75em 1.25em;
    font-family: "Avenir-Medium", sans-serif;
  }
  .mat-dialog-restore {
    font-size: 1em;
    line-height: 1.25em;
    letter-spacing: -0.011875em;
    color: #FFFFFF;
    opacity: 1;
    background: #2065F8 0% 0% no-repeat padding-box;
    box-shadow: 0em 0.25em 0.5em #0000000A;
    border-radius: 0.5em;
    padding: 0.75em 1.25em;
    font-family: "Avenir-Medium", sans-serif;
  }
  .disable-publish-btn{
    background: #AAAAAA 0% 0% no-repeat padding-box;
    box-shadow: 0em 0.25em 0.5em #0000000A;
    border-radius: 0.5em;
    border: 0.125em solid #AAAAAA;
    opacity: 1;
    font-family: "Avenir-Medium", sans-serif;
  }
}
.mat_auto_complete_chip  {
  input {
    height: 0;
  }
  height: auto;
  .mat-form-field-label {
    transform: translateY(-1.57498em) scale(0.75);
  }
  mat-label {
    color: #002F82;
    font-size: 0.75em;
    line-height: 1em;
    letter-spacing: 0.1em;
    opacity: 1;
    font-family: "Avenir-Medium", sans-serif;
  }
}
.mat_auto_complete_chip.mat-focused {
  input {
    height: 1.5em;
  }
}
.mat_auto_complete_chip .mat-form-field-infix {
  padding: 0.407em 0em;
  min-height: 1em;

}
.mat-option-disabled {
  .mat-option-text{
    color: #AAAAAA;
  }
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #2065F866;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #2065F8;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #2065F8;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #2065F8;
}
.mat-radio-label-content {
  font-family: "Avenir-Medium", sans-serif;
  font-size:1em;
  line-height: 1.5em;
  letter-spacing: 0.009375em;
  color: #222222;
  opacity: 1;
}
.mat-radio-outer-circle {
  color: #00000099;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
    color:transparent;
}
.mat-form-field-wrapper {
  padding-bottom: 0;
}
.mat-slide-toggle-content {
  font-family: "Avenir-Medium", sans-serif;
  font-size: 1em;
  line-height: 1.5em;
  letter-spacing: 0.009375em;
  color: #222222;
  opacity: 1;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  display: none;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #707070;
}